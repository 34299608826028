import {React, useState, useEffect} from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Icon } from "@iconify/react";
import assignmentCheck from "@iconify-icons/zmdi/assignment-check";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import HeaderLower from "./HeaderLower";
import LogService from "../services/log.service";

function TestOurTech_thankyou (props) {
  const [website, setWebsite] = useState(window.location.href);

  
  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

    if(!props.history.location.state?.showroom){

        return <Redirect to="/" />;
    }
    return (
      <>
        <div className="container-fluid">
          <Header />
          <HeaderLower />
           <div className="white-box thank">
            <h1 className="text-center">Thank You! </h1>
            <Icon icon={assignmentCheck} />
            {/* <p>
              You will receive an email with your unique code from our
              fulfillment center. Once you receive your email you will be given
              instructions on redeeming your Reward Card. For further
              information please call{" "}
              <a href="tel:866-ELITE-21">866-ELITE-21</a> or email us at{" "}
              <a href="mailto:customercare@eliterewards.biz">
                customercare@eliterewards.biz
              </a>{" "}
              and one of our customer service representatives will be happy to
              assist you.
            </p> */}
            <a href="/" className="btn btn-md btn-primary">
              Go Back
            </a>
          </div>
        </div>
        <Footer />
      </>
    );
  }
export default TestOurTech_thankyou;
