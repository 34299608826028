import React from "react";
const HeaderLower = () => {
  return (
    <div className="header_lower">
      {/* Try a Purple at a Mattress Firm and Enter to Win a Beach Getaway! <br/>(Retail value $5,000). */}
      {/* Try a Purple at a Levin Furniture and Enter to Win a Hawaii Getaway! <br/>(Retail value $6,500). */}
      {/* Try a Purple and Enter To Win a Hawaii Getaway! <br/>(Retail Value $6,500). */}
      Try a Purple and Enter To Win a Sports Flyaway! <br />
      (Retail Value $2,200).
    </div>
  );
};
export default HeaderLower;
