import { React, useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Header from "./Header";
import Footer from "./Footer";
import HeaderLower from "./HeaderLower";
import swal from "sweetalert";
// import Recaptcha from "react-recaptcha";
import LogService from "../services/log.service";
import RegisterService from "../services/register.service";

function RegisterForm(props) {
  const [fields, setFields] = useState({
    sales_name: "",
    name: "",
    lname: "",
    emailid: "",
    showroom: "",
    address: "",
    phone: "",
    question: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [value_userform, setValue_userform] = useState([]);

  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  localStorage.setItem("isRegister", false);
  const ref = useRef();
  useEffect(() => {
    test();
  }, []);

  async function test() {
    let errors = {};

    try {
      //FIXME remove this service if not used
      const response = await RegisterService.getStates();
      var value = response.data.data;
      this.setState({
        value: value,
      });
      if (response.data.status === 429 || response.data.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      if (value.includes("redirect")) {
      } else {
        errors["questionnare"] = response.data.response;
      }
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
  }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const checkedValue = (e) => {
    let value = "yes";
    if (e === false || e === "") {
      value = "no";
    }
    return value;
  };

  const handleChange = (e) => {
    let { value, checked, name } = e.target;
    if (name === "showroom" && value === "true") {
      value = "";
    }
    if (name !== "question") {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: checked,
        };
      });
    }
  };

  const saveErrors = (err) => {
    setErrors(err);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        sales_name: fields?.sales_name,
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        showroom: fields?.showroom,
        address: fields?.address,
        phone: fields?.phone,
        question: checkedValue(fields?.question),
      };
      var username = process.env.REACT_APP_API_USERNAME;
      var password = process.env.REACT_APP_API_PASSWORD;
      try {
        try {
          LogService.createFormLog(website, "POST", data);
        } catch (e) {
          console.log(e);
        }

        const responseuserform = RegisterService.regData(data);
        responseuserform
          .then((response) => {
            LogService.apiResLog(website, JSON.stringify(response.data));

            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);
            localStorage.removeItem("isRegister");
            swal({
              title: "Successful!",
              text: "Form Submitted!",
              type: "success",
              icon: "success",
            }).then(() => {
              props.history.push({
                pathname: "/TestOurTech_thankyou",
                state: {
                  sales_name: fields?.sales_name,
                  name: fields.name,
                  lname: fields.lname,
                  emailid: fields.emailid,
                  showroom: fields.showroom,
                  address: fields?.address,
                  phone: fields?.phone,
                  question: fields?.question,
                },
              });
            });
            if (value_userform?.includes("redirect")) {
            }
          })
          .catch((err) => {
            LogService.apiResLog(website, JSON.stringify(err.response.data));

            let errorData = {};
            errorData["sales_name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name &&
              err?.response?.data?.message.sales_name[0];
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["showroom"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.showroom &&
              err?.response?.data?.message.showroom[0];
            errorData["address"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.address &&
              err?.response?.data?.message.address[0];
            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            ref?.current?.focus();
          });
      } catch (err) {
        if (err.response.status == 429 || err.response.status == "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["sales_name"] = err?.response?.data?.message.sales_name[0];
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["showroom"] = err?.response?.data?.message.showroom[0];
        errorData["address"] = err?.response?.data?.message.address[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];

        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;
    // if (!captcha) {
    //   isformIsValid = false;
    //   errors["checkederrorcaptcha"] = "Captcha not verified";
    //   saveErrors(errors);
    //   return isformIsValid;
    // }
    // if (!i_agree) {
    //   isformIsValid = false;
    //   errors["checkederror"] = "Select the checkbox";
    //   setErrors(errors);

    //   return false;
    // }

    return isformIsValid;
  };
  // const onLoadRecaptcha = () => {};

  return (
    <>
      <div className="container-fluid">
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Register to score your free Reward Card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Customer Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="showroom"
                    id="showroom"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Showroom Location</option>
                    <option value="Alexandria">Alexandria</option>
                    <option value="Bear">Bear</option>
                    <option value="Bensalem">Bensalem</option>
                    <option value="Brick">Brick</option>
                    <option value="Burlington">Burlington</option>
                    <option value="Capitol Heights">Capitol Heights</option>
                    <option value="Catonsville">Catonsville</option>
                    <option value="Chambersburg">Chambersburg</option>
                    <option value="Churchville">Churchville</option>
                    <option value="Columbia">Columbia</option>
                    <option value="Cottman Avenue">Cottman Avenue</option>
                    <option value="Dover">Dover</option>
                    <option value="Downingtown">Downingtown</option>
                    <option value="Easton">Easton</option>
                    <option value="Eatontown">Eatontown</option>
                    <option value="Fairfax">Fairfax</option>
                    <option value="Falls Church">Falls Church</option>
                    <option value="Frederick">Frederick</option>
                    <option value="Freehold">Freehold</option>
                    <option value="Gaithersburg">Gaithersburg</option>
                    <option value="Glen Burnie">Glen Burnie</option>
                    <option value="Green Brook">Green Brook</option>
                    <option value="Hagerstown">Hagerstown</option>
                    <option value="Harrisburg">Harrisburg</option>
                    <option value="Hunt Valley">Hunt Valley</option>
                    <option value="Lancaster">Lancaster</option>
                    <option value="Ledgewood">Ledgewood</option>
                    <option value="Lexington Park">Lexington Park</option>
                    <option value="Linden">Linden</option>
                    <option value="Manahawkin">Manahawkin</option>
                    <option value="Maple Shade">Maple Shade</option>
                    <option value="Mechanicsburg">Mechanicsburg</option>
                    <option value="Middletown">Middletown</option>
                    <option value="Montgomeryville">Montgomeryville</option>
                    <option value="North Brunswick">North Brunswick</option>
                    <option value="Pasadena">Pasadena</option>
                    <option value="Phillipsburg">Phillipsburg</option>
                    <option value="Pottstown">Pottstown</option>
                    <option value="Quakertown">Quakertown</option>
                    <option value="Rosedale">Rosedale</option>
                    <option value="Staten Island">Staten Island</option>
                    <option value="Toms River">Toms River</option>
                    <option value="Waldorf">Waldorf</option>
                    <option value="Warminster">Warminster</option>
                    <option value="Whitehall">Whitehall</option>
                    <option value="Wilmington">Wilmington</option>
                    <option value="Wind Gap">Wind Gap</option>
                    <option value="York">York</option>

                    {/* <option value="2525 N. Harlem Ave. Chicago, IL  60707-2094">
                      2525 N. Harlem Ave. Chicago, IL 60707-2094
                    </option>
                    <option value="8027 S.  Cicero, Chicago, IL 60652">
                      8027 S. Cicero, Chicago, IL 60652
                    </option>
                    <option value="2221 Richmond Rd, McHenry, IL 60051">
                      2221 Richmond Rd, McHenry, IL 60051
                    </option>
                    <option value="18150 S Halsted St,  Homewood, il 60430">
                      18150 S Halsted St, Homewood, il 60430
                    </option>
                    <option value="732 North Route 59, Aurora, IL 60504">
                      732 North Route 59, Aurora, IL 60504
                    </option>
                    <option value="40 East Rand Road, Arlington Heights, 60004">
                      40 East Rand Road, Arlington Heights, 60004
                    </option>
                    <option value="1500 W. 75th St, Downers Grove, IL, 60516">
                      1500 W. 75th St, Downers Grove, IL, 60516
                    </option>
                    <option value="215 Gary Ave, bloomingdale, IL 60108">
                      215 Gary Ave, bloomingdale, IL 60108
                    </option>
                    <option value="1000-46 Rohlwing Rd, Lombard, IL 60148">
                      1000-46 Rohlwing Rd, Lombard, IL 60148
                    </option>
                    <option value="2575 N. Elston Ave Chicago, IL  60647">
                      2575 N. Elston Ave Chicago, IL 60647
                    </option>
                    <option value="14920 S. LaGrange Rd Orland Park, IL  60462">
                      14920 S. LaGrange Rd Orland Park, IL 60462
                    </option>
                    <option value="1600 E 80th Ave Merrillville, IN">
                      1600 E 80th Ave Merrillville, IN
                    </option>
                    <option value="156 E. Golf Rd. Schaumburg, IL 60173">
                      156 E. Golf Rd. Schaumburg, IL 60173
                    </option>
                    <option value="2724 Plainfield, Joliet, IL  60435">
                      2724 Plainfield, Joliet, IL 60435
                    </option>
                    <option value="1400 S Randall Road Algonquin, IL 60102">
                      1400 S Randall Road Algonquin, IL 60102
                    </option>
                    <option value="14640 U.S. Hwy 31 North Carmel, IN , 46032">
                      14640 U.S. Hwy 31 North Carmel, IN , 46032
                    </option>
                    <option value="7340 E. State St Rockford, IL 61108">
                      7340 E. State St Rockford, IL 61108
                    </option>
                    <option value="7609 Shelby St, Indianapolis, IN  46227">
                      7609 Shelby St, Indianapolis, IN 46227
                    </option>
                    <option value="5651 E 86th St, Indianapolis, IN 46250">
                      5651 E 86th St, Indianapolis, IN 46250
                    </option>
                    <option value="8401 N Michigan Road , IN 46268">
                      8401 N Michigan Road , IN 46268
                    </option>
                    <option value="2575 E Main St Plainfiled, 46168">
                      2575 E Main St Plainfiled, 46168
                    </option>
                    <option value="8301 E Washington St, Indianapolis, in">
                      8301 E Washington St, Indianapolis, in
                    </option>
                    <option value="2200 W. War Memorial Drive Peoria, Illinois 61613">
                      2200 W. War Memorial Drive Peoria, Illinois 61613
                    </option>
                    <option value="6148 W Grand Ave, Gurnee, Illinois 60031">
                      6148 W Grand Ave, Gurnee, Illinois 60031
                    </option>
                    <option value="7014B Green Bay Rd, kenosha, WI">
                      7014B Green Bay Rd, kenosha, WI
                    </option>
                    <option value="3333 W Touhy Ave, Lincolnwood, IL 60712">
                      3333 W Touhy Ave, Lincolnwood, IL 60712
                    </option> */}
                  </select>
                  <div className="errorMsg">
                    {errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sale Associate Name"
                    style={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name"
                    id="sales_name"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name &&
                    !errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null}
                  </div>
                </div>
                <br />

                <li>
                  <label>
                    <input
                      type="checkbox"
                      id="question"
                      name="question"
                      className="conditions"
                      value={fields.question}
                      onChange={handleChange}
                    />
                    <span>
                      {" "}
                      Sign up to receive Email and SMS updates from R&S
                      Distributing LLC dba Ashley and Purple.
                    </span>
                    {/* <div className="errorMsg">{errors.checkederror}</div> */}
                  </label>
                </li>

                {/* <br />
                <br />
                <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={onLoadRecaptcha}
                  />
                </div>
                <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}

                <br />
                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn1 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterForm;
